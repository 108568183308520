import dayjs from "lib/dayjs";
import { Fragment, useEffect, useMemo, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useSubscriptions, useUserData } from "features/User";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";
import { find } from "lodash";
import { ArrowBackOutlined } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";

export default function PaymentStatusPage() {
  const [searchParams] = useSearchParams();
  const { getCheckoutSessionStatus } = useSubscriptions();
  const { getSubscriptionInfo, getUserInfo } = useUserData();

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  function getProrationDate(endMonth) {
    const currentMonth = dayjs().month();
    const endOfMonth = dayjs().month(endMonth).endOf("month");
    if (currentMonth <= endMonth) {
      return endOfMonth;
    }
    return endOfMonth.add(1, "year");
  }

  useEffect(() => {
    async function fetchData(sessionId) {
      const session = await getCheckoutSessionStatus(sessionId);
      await getUserInfo();
      getSubscriptionInfo();
      setSession(session);
      setLoading(false);
    }

    if (
      searchParams.has("sessionId") &&
      (session === null || session.id !== searchParams.get("sessionId"))
    ) {
      setLoading(true);
      fetchData(searchParams.get("sessionId"));
    }
  }, [searchParams, session]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>Payment Confirmation</PageTitle>
        {session &&
        (session.status === "complete" || session.status === "open") ? (
          <Fragment>
            <Typography>
              Thank you for subscribing to Sooth! For your convenience your
              transaction is listed below.
            </Typography>
            {!session.isPaid && (
              <Alert severity="warning">
                <AlertTitle>Electronic Check Payments</AlertTitle>
                <Typography>
                  If you purchased your Sooth subscription using a method of
                  Electronic Check (Bank Transfer or Bank Account) then your
                  account will be in a "Pending" status until your funds have
                  cleared. This can take up to 4-5 business days once initiated.
                </Typography>
              </Alert>
            )}
            <TableContainer component={Paper} variant="outlined" square>
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    borderBottom: "none",
                    py: 1,
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {session.lineItems.map((item) => {
                    const groupItem = find(
                      session.metadata.planTypeData,
                      (seatLevel) =>
                        seatLevel.StripeProductId === item.productId
                    );
                    return (
                      <TableRow key={item.id}>
                        <TableCell>
                          {item.description}{" "}
                          {groupItem != null ? (
                            <Box component="span">
                              (Seats:{" "}
                              <NumericFormat
                                value={groupItem.NoOfLicenses}
                                defaultValue="-"
                                displayType="text"
                                thousandSeparator=","
                                thousandsGroupStyle="thousand"
                              />
                              )
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">
                          <NumericFormat
                            value={item.subtotal}
                            decimalScale={2}
                            prefix="$"
                            defaultValue="-"
                            displayType="text"
                            fixedDecimalScale={true}
                            thousandSeparator=","
                            thousandsGroupStyle="thousand"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      rowSpan={
                        session.metadata.isProrationRequired
                          ? session.isDiscounted
                            ? 3
                            : 2
                          : session.isDiscounted
                          ? 4
                          : 3
                      }
                      sx={{ verticalAlign: "bottom" }}
                    >
                      <Typography variant="table" fontWeight="bold">
                        Purchase Date:
                      </Typography>{" "}
                      {dayjs(session.created).format("ll")}
                    </TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={session.subtotal}
                        decimalScale={2}
                        prefix="$"
                        defaultValue="-"
                        displayType="text"
                        fixedDecimalScale={true}
                        thousandSeparator=","
                        thousandsGroupStyle="thousand"
                      />
                    </TableCell>
                  </TableRow>
                  {session.isDiscounted && (
                    <TableRow>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">
                        <Typography
                          component="span"
                          variant="table"
                          color="error"
                        >
                          <NumericFormat
                            value={session.discount}
                            decimalScale={2}
                            prefix="$-"
                            defaultValue="-"
                            displayType="text"
                            fixedDecimalScale={true}
                            thousandSeparator=","
                            thousandsGroupStyle="thousand"
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={session.total}
                        decimalScale={2}
                        prefix="$"
                        defaultValue="-"
                        displayType="text"
                        fixedDecimalScale={true}
                        thousandSeparator=","
                        thousandsGroupStyle="thousand"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {session.metadata.isProrationRequired && (
                      <TableCell rowSpan={1} sx={{ verticalAlign: "top" }}>
                        <Typography variant="table" fontWeight="bold">
                          Prorated End Date:
                        </Typography>{" "}
                        {getProrationDate(
                          session.metadata.proratedMonth
                        ).format("ll")}
                      </TableCell>
                    )}
                    <TableCell align="right">Amount Due</TableCell>
                    <TableCell align="right">
                      <NumericFormat
                        value={session.isPaid ? 0 : session.total}
                        decimalScale={2}
                        prefix="$"
                        defaultValue="-"
                        displayType="text"
                        fixedDecimalScale={true}
                        thousandSeparator=","
                        thousandsGroupStyle="thousand"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {session.metadata.planTypeData != null && (
              <Fragment>
                <Typography>
                  You can find your new group management controls in the left
                  hand menu under profile, or click below for quick access!
                </Typography>
                <Stack direction="row">
                  <Button
                    LinkComponent={NavLink}
                    to="/profile/group/user-managment"
                  >
                    Group User Management
                  </Button>
                  <Button
                    LinkComponent={NavLink}
                    to="/profile/group/group-controls"
                  >
                    Group Controls
                  </Button>
                </Stack>
              </Fragment>
            )}
          </Fragment>
        ) : loading ? (
          <LinearProgress />
        ) : (
          <Typography>Checkout session not found.</Typography>
        )}
      </Stack>
      <Footer sx={{ px: 0 }} />
    </Box>
  );
}
