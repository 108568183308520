import { Box, Link, Typography } from "@mui/material";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";

import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";

export default function SubscriptionAgreementPage() {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
        "& > p + p": {
          mt: 2,
        },
        "& > ul": {
          listStylePosition: "outside",
        },
      }}
    >
      <PageTitle gutterBottom>Site Access Agreement</PageTitle>
      <Typography>
        <Typography component="span" variant="h6">
          Agreement:
        </Typography>{" "}
        This Site Access Agreement (the “Agreement”) is entered into by: Sooth
        Inc. (hereinafter "Company", “us“, “our“, or “we“) located at 330 N
        Belmont Ave, Wichita, Kansas 67208 and you or the entity you represent
        as a paying customer (hereinafter "Customer") of the site
        https://app.sooth.fyi (the “Site”) for the purpose of providing Customer
        with extended, paid access to the Company's Site including updates,
        upgrades, fixes or revisions as may occur or be provided under this
        Agreement.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Provision of Services:
        </Typography>{" "}
        Company will provide Customer access, maintenance and other hosting
        services to the Site. Customer agrees to cooperate with Company in
        performing these services. The Customer shall be responsible for
        maintaining the confidentiality of passwords and shall permit only
        authorized users with access to the Site. Customer acknowledges the
        Company does not create content but provides access the third party
        websites, services and content.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Customer Support:
        </Typography>{" "}
        Company will provide live telephone, email and/or remote support to
        Customer during the hours of 8:00am to 5:00pm (eastern time) Monday
        through Friday. Holiday hours may be limited.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Customer Responsibilities:
        </Typography>{" "}
        Customer agrees that it shall provide its own internet access at the
        Customer’s location required to access the Site. The Company's Site is
        only to be used for lawful purposes. Customer agrees not to transmit or
        store any materials through the Site that could be harmful to the Site
        or violate any laws or regulations, including without limitation laws
        related to infringement of intellectual property. Except as may be
        permitted by Company in writing, Customer agrees that it will not rent,
        lease, sub-license, re-sell, time share or otherwise assign to any third
        party this Agreement, or any part thereof, or any of the Customer's
        rights or authority to use Site under this Agreement. Customer and its
        End Users may only use the Services as permitted and described in this
        Agreement, the Terms of Use and Privacy Policy, as may be amended, which
        may be viewed at the Company’s website (the “Documentation”). The Terms
        of Use and Privacy Policy are incorporated into this Agreement and made
        a part hereof.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Proprietary Rights:
        </Typography>{" "}
        This Site is the exclusive property of the Company, and it is protected
        by one or more patents, copyrights and/or trade secrets. Except for
        limited access right granted to the Customer in this agreement, all
        rights, title and interest in and to the Site (including, but not
        limited to, any and all coding, copyrights, patents, patent
        applications, trademarks and trade names, trade secrets, and any
        information embodying proprietary data relating to the Site) and the
        Company’s system(s) are and shall remain the exclusive property of the
        Company. Customer may not alter, copy, sell, or transfer the Site
        without the express permission of the Company.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Pricing and Payment:
        </Typography>{" "}
        Pricing and Payment terms can be found in Customer’s Subscription
        Management portal on the Site and are incorporated into this Agreement
        and made a part hereof. All prices are quoted and shall be paid in US
        Dollars. Company may have periodic price increases for renewal terms.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Renewal invoices:
        </Typography>{" "}
        Customers will receive renewal reminders as set forth below:
      </Typography>
      <ul>
        <li>
          Individual Subscribers will be automatically renewed and will receive
          a renewal reminder approximately ten (10) days prior to the
          subscription renewal date. Payment for the renewal will be
          automatically processed on the due date. Failure to make the required
          renewal payment may result in restricted account access.
        </li>
        <li>
          Group Subscriptions will not be automatically renewed. Group
          administrators will receive a renewal reminder approximately sixty
          (90) days prior to the subscription renewal date and periodically
          thereafter. Payment will be due by the Group Subscription renewal date
          or account access may be restricted.
        </li>
      </ul>

      <Typography>
        <Typography component="span" variant="h6">
          Term:
        </Typography>{" "}
        The Term of this Agreement can be found in Customer's Subscription
        Management portal on the Site. Upon termination, the Customer will no
        longer have access to the Site.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Cancellation:
        </Typography>{" "}
        Customer may cancel subscription to the Site at any time by following
        the instructions in Customer's Subscription Management portal on the
        Site. Upon cancellation, Customer will retain access to the Site until
        the Term has lapsed. No refunds will be granted.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Warranties:
        </Typography>{" "}
        Company warrants to the Customer that the Site will perform as designed
        and the services will be performed in a professional and workmanlike
        manner with minimal downtime.
      </Typography>

      <Typography>
        For the purposes of this agreement, “downtime” shall mean an
        interruption in the availability of hosting services to Customer due to
        an error in the Site.
      </Typography>

      <Typography>
        The Company, except as may be limited or prohibited by applicable law,
        does not warrant the Site will meet the customer’s requirements, run
        free of errors or omissions or uninterrupted. Although, the Company will
        use all reasonable efforts to maintain security, confidentiality and
        integrity of the information, the Company cannot and does not guarantee
        privacy, security, and authenticity of any information transmitted
        through, or stored in any system connected to the internet.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Training:
        </Typography>{" "}
        A limited number of live ‘virtual’ training sessions will be provided
        during the implementation. After going ‘live’ with the Site, users will
        have unlimited access to training videos.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Limitation of Liabilities:
        </Typography>{" "}
        Except as may be limited or prohibited by applicable law, Company's
        entire liability under this agreement for any damages from any cause
        whatsoever, regardless of form or action, whether in contract,
        negligence or otherwise, shall in no event exceed the amount equal to
        the annual price paid for the services out of which the claim arose.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Confidential/Privacy Information:
        </Typography>{" "}
        Each party agrees to keep confidential and to use only for purposes of
        performing this Agreement any confidential information provided to it by
        the other party.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Data Protection and other Compliance Obligations:
        </Typography>{" "}
        Both parties shall comply with their respective obligations under
        applicable data privacy laws with respect to Personal Information,
        including the Family Educational Rights and Privacy Act of 1974, 20 USC
        1232g and its implementing regulations, as they may be amended from time
        to time (“FERPA”), the Protection of Pupil Rights Amendment (“PPRA”),
        and the Children’s Online Privacy Protection Act (“COPPA”). Company will
        maintain reasonable and appropriate security measures designed to
        protect Personal Information from unauthorized access, destruction, use,
        modification and disclosure.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Customer Data Privacy Obligations:
        </Typography>{" "}
        The Customer shall maintain reasonable and appropriate security measures
        to protect the confidentiality and integrity of its account IDs,
        passwords, and interaction with the Site. The Customer shall be
        responsible for all account activities regardless of whether the
        activities are authorized by the Customer or undertaken by the Customer,
        its employees and other representatives or authorized users and Company
        is not responsible for unauthorized access to your account. Customer
        shall also comply with all applicable laws and regulatory requirements
        governing Personal Information utilized and transferred in connection
        with the Site and services.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Student Data:
        </Typography>{" "}
        Personal Information of students, student records and student-generated
        content (collectively, “Student Data”) is the property of the applicable
        student or legal guardian of the student. During the Term of this
        Agreement, the Customer shall retain control of all Student Data
        maintained in connection with the Site and services. Company shall take
        reasonable commercial measures designed to ensure the security and
        confidentiality of all Student Data. Company and its employees, agents
        and contractors shall use Student Data only for purposes consistent with
        the terms of this Agreement.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          FERPA:
        </Typography>{" "}
        Student Data may include “education records” as defined under FERPA. To
        the extent that Company collects or processes Personal Information in
        education records in the course of providing services under this
        Agreement, it does so as an outsourced institutional function pursuant
        to FERPA 34 CFR Part 99.31(a)(1) and is designated by Customer for these
        purposes as a “school official” with legitimate educational interests.
        In this regard, Company will comply with its responsibilities as a
        school official under FERPA. Similarly, Customer will comply with the
        responsibilities applicable to it under FERPA. Customer is responsible
        to obtain any necessary consents prior to providing Student Data to
        Company and is wholly responsible for providing annual notice to
        students and parents or other legal guardians of their rights with
        respect to FERPA and other applicable law.
      </Typography>

      <Typography variant="h6" mt={2}>
        Notices:
      </Typography>
      <ul>
        <li>
          All notices to the Company as required by this Agreement shall be in
          writing and may be delivered via US Mail, email or courier. Company’s
          email and mailing address for these purposes is below.
        </li>
        <li>
          All notices to Individual Subscribers as required by this Agreement
          shall be in writing and will be delivered via email using the
          Individual Subscriber’s account email.
        </li>
        <li>
          All notices to Group Subscriptions as required by this Agreement shall
          be in writing and will be delivered via email using the Account
          Administrator’s account email.
        </li>
        <li>
          Parties may change any of its notice addresses from time to time by
          giving the other party written notice of such change.
        </li>
        <li>
          If sent via email, all notices shall be deemed received when sent
          provided the sender has proof of delivery.
        </li>
      </ul>

      <Typography>
        <Typography component="span" variant="h6">
          Other Agreements:
        </Typography>{" "}
        Company and Customer may have also entered into other relevant
        agreements with each other (“Other Agreements”) with overlapping terms
        and conditions, such as the Standard Student Data Privacy Agreement and
        / or a Master License and Service Agreement, as the case may be. In the
        event there is a conflict between any of the terms of this Agreement and
        any of the terms in these Other Agreements, the terms of the Other
        Agreements shall prevail.
      </Typography>

      <Typography>
        <Typography component="span" variant="h6">
          Final Agreement:
        </Typography>{" "}
        Notwithstanding the preceding paragraph, this Agreement constitutes the
        entire agreement of the parties and supersedes any and all prior
        agreements between them on the matters covered by this Agreement.
      </Typography>

      <Typography>
        Each party agrees to the terms and conditions contained in the
        Agreement.
      </Typography>

      <Typography variant="h6" mt={2}>
        Company:
      </Typography>
      <Typography whiteSpace="pre-line" mb={2}>
        Sooth Inc.
        <br />
        Email: <Link href="mailto:contact@sooth.fyi">contact@sooth.fyi</Link>
        <br />
        Address: 330 N Belmont Ave, Wichita, Kansas 67208
        <br />
        Phone Number: 316.253.2791
      </Typography>
      <Footer sx={{ px: 0 }} />
    </Box>
  );
}
