import { Box, Typography } from "@mui/material";
import { staticsite } from "config";
import { Link } from "react-router-dom";

export default function Terms({ logEvent, wordpressURL }) {
  return (
    <Box>
      <Typography variant="body2">
        By signing in to Sooth.fyi, you agree to our{" "}
        <Link
          to={`https://${staticsite}sooth.fyi/terms-of-use/`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) =>
            logEvent(`click_footer_terms`, {
              ["terms"]: "clicked",
              url: `https://${staticsite}sooth.fyi/terms-of-use/`,
            })
          }
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          to={`https://${staticsite}sooth.fyi/privacy-policy/`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) =>
            logEvent(`click_footer_privacy`, {
              ["privacy"]: "clicked",
              url: `https://${staticsite}sooth.fyi/privacy-policy/`,
            })
          }
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Box>
  );
}
