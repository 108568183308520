import { Box } from "@mui/material";
import Footer from "components/layout/Footer";
import ExplorePage from "features/Explore";
import React from "react";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";

export default function PublicIndexPage() {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
      }}
    >
      <ExplorePage />
      <Footer sx={{px: 0}} />
    </Box>
  );
}
