import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, SvgIcon, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { getFormattedDate, getRegularDate } from "utils/time.util";
import {
  setBoclipsPopup,
  setBoclipsVideoId,
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";

import { ReactComponent as summarizerLogo } from "assets/icons/summarizer-icon.svg";
import { ReactComponent as citationLogo } from "assets/icons/citation-generator-icon.svg";
import { ReactComponent as readingLogo } from "assets/icons/reading-level-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import ColumnsSection from "./ColumnSection";
import getRefreshTOkenWashingtonPost from "hooks/use-washinghton-token";
import { debounce } from "lodash";

export default function RecentDataGrid({ recentCollectionSource }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const defaultVisibilityModel = {
    createdDateTime_dtm: false,
    title_chr: true,
    collectionName_chr: true,
    sourceName_chr: true,
    sourcePublishedDateTime_dtm: true,
    score: false,
    mediaBiasRating: false,
    charityNavigatorSource: false,
    isSummaryAvailable_ysn: false,
    isCitationAvailable_ysn: true,
    isReadingLevelAvailable_ysn: false,
    notesCount_cnt: true,
  };

  const { user } = useSelector((state) => state.auth);

  const [visibilityModel, setVisibilityModel] = useLocalStorage(
    `recent-columnvisibility-${user.userId}`,
    defaultVisibilityModel
  );

  useEffect(() => {
    if (!visibilityModel || Object.keys(visibilityModel).length === 0) {
      setVisibilityModel(defaultVisibilityModel);
      writeStorage(
        `recent-columnvisibility-${user.userId}`,
        defaultVisibilityModel
      );
      apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
    } else {
      apiRef.current.setColumnVisibilityModel(visibilityModel);
    }
  }, []);

  const [persistedWidths, setPersistedWidths] = useLocalStorage(
    `recent-columnWidths-${user.userId}`,
    {}
  );

  const [commonWidth, setCommonWidth] = useState(null);

  const columnWidthsRef = useRef(persistedWidths);
  const containerRef = useRef(null);

  const computeCommonWidth = () => {
    const element = containerRef.current;
    let width = element ? element.clientWidth : 0;  

    const visibleFields = Object.keys(visibilityModel).filter(
      (field) => visibilityModel[field]
    );
    const unsetCount = visibleFields.length;
    const newCommonWidth =
      unsetCount > 0 ? Math.floor(width / unsetCount) : 0;
    setCommonWidth(newCommonWidth);
  };

  const debouncedUpdateColumnWidths = () =>
  {
    writeStorage(
      `recent-columnWidths-${user.userId}`,
      columnWidthsRef.current
    );
    setPersistedWidths(columnWidthsRef.current);
    computeCommonWidth();
  };

  const handleColumnResize = (params) => {
    columnWidthsRef.current = {
      ...columnWidthsRef.current,
      [params.colDef.field]: params.width,
    };
    debouncedUpdateColumnWidths();
  };

    useEffect(() => {
    if (Object.keys(persistedWidths).length === 0) {
      setCommonWidth(null);
    }
    }, [persistedWidths]);

  const handleVisibilityChange = (newVisibilityModel) => {
    const updatedVisibility = {
      ...visibilityModel,
      ...newVisibilityModel,
    };
    setVisibilityModel(updatedVisibility);
    writeStorage(`recent-columnvisibility-${user.userId}`, updatedVisibility);
  };

  const handleSidebarOpen = (item, option) => {
    dispatch(setLevel(""));
    dispatch(setSelectedBookmarkOption(option));
    dispatch(setReadingLevelAvailable(item.isReadingLevelAvailable_ysn));
    dispatch(
      setSummaryInfoData({
        publisher: item.sourceName_chr,
        title: item.title_chr,
        published_date: item.sourcePublishedDateTime_dtm,
        url: item.sourceUrl_chr,
      })
    );
    dispatch(setCollectionSourceId(item.collectionSourceID_ids));
    dispatch(setCollectionId(item.collectionID_lng));
    dispatch(setIsCitationAvailable(item.isCitationAvailable_ysn));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  const columns = useMemo(
    () => [
  {
    field: "createdDateTime_dtm",
    headerName: "Added",
    type: "date",
    ...(persistedWidths["createdDateTime_dtm"]
      ? { flex: 0, width: persistedWidths["createdDateTime_dtm"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    valueGetter: (value, row, column, apiRef) => {
      return value ? new Date(value) : null;
    },
    renderCell: (params) => {
      const date = params.value ? getRegularDate(params.value) : "-";
      return (
        <Typography
          noWrap
          variant="inherit"
          color={theme.palette.text.secondary}
          onClick={() => handleSidebarOpen(params.row, "notes")}
          sx={{
            cursor: "pointer",
          }}
        >
          {date || "-"}
        </Typography>
      );
    },
  },
  {
    field: "title_chr",
    headerName: "Title",
    ...(persistedWidths["title_chr"]
      ? { flex: 0, width: persistedWidths["title_chr"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 2 }),
    renderCell: (params) => {
      const { sourceUrl_chr } = params.row;

      const handleLinkClick = async (event) => {
        event.preventDefault();

        let resolvedUrl = sourceUrl_chr;
        if (resolvedUrl.includes("boclips.com")) {
          const id = resolvedUrl.split("/").pop();
          dispatch(setBoclipsVideoId(id));
          dispatch(setBoclipsPopup(true));
          return;
        }

        if (sourceUrl_chr && sourceUrl_chr.includes("washingtonpost.com")) {
          resolvedUrl = await getRefreshTOkenWashingtonPost(sourceUrl_chr);
        }

        if (resolvedUrl.includes("foreignpolicy.com")) {
          resolvedUrl += "?auth=sooth";
        }

        window.open(resolvedUrl, "_blank");
      };

      return sourceUrl_chr ? (
        <Typography
          variant="inherit"
          noWrap
          sx={{
            fontSize: "1.3rem",
            cursor: "pointer",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={handleLinkClick}
        >
          {params.value || "-"}
        </Typography>
      ) : (
        <Typography variant="inherit" noWrap sx={{ fontSize: "1.3rem" }}>
          {params.value || "-"}
        </Typography>
      );
    },
  },
  {
    field: "collectionName_chr",
    headerName: "Collection",
    ...(persistedWidths["collectionName_chr"]
      ? { flex: 0, width: persistedWidths["collectionName_chr"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Typography
        fontSize={"1.3rem"}
        variant="inherit"
        noWrap
        fontWeight={"bold"}
        color={"#6c6c6c"}
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          cursor: "pointer",
        }}
      >
        {params.value || "-"}
      </Typography>
    ),
  },
  {
    field: "sourceName_chr",
    headerName: "Source",
    ...(persistedWidths["sourceName_chr"]
      ? { flex: 0, width: persistedWidths["sourceName_chr"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Typography
        fontSize={"1.3rem"}
        variant="inherit"
        noWrap
        fontWeight={"bold"}
        color={"#6c6c6c"}
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          cursor: "pointer",
        }}
      >
        {params.value || "-"}
      </Typography>
    ),
  },
  {
    field: "sourcePublishedDateTime_dtm",
    headerName: "Pub Date",
    ...(persistedWidths["sourcePublishedDateTime_dtm"]
      ? { flex: 0, width: persistedWidths["sourcePublishedDateTime_dtm"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    type: "date",
    valueGetter: (value, row, column, apiRef) => {
      return value ? new Date(value) : null;
    },
    renderCell: (params) => {
      const date = params.value ? getRegularDate(params.value) : "-";
      return (
        <Typography
          noWrap
          onClick={() => handleSidebarOpen(params.row, "notes")}
          variant="inherit"
          color={"#6c6c6c"}
          sx={{
            cursor: "pointer",
          }}
        >
          {date || "-"}
        </Typography>
      );
    },
  },
  {
    field: "score",
    headerName: "NewsGuard",
    ...(persistedWidths["score"]
      ? { flex: 0, width: persistedWidths["score"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Box
        sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
      >
        {params.value ? (
          <NewsguardScore score={Number(params.value)} size="small" />
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    field: "mediaBiasRating",
    headerName: "AllSides",
    ...(persistedWidths["mediaBiasRating"]
      ? { flex: 0, width: persistedWidths["mediaBiasRating"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Box
        sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
      >
        {params.value ? (
          <AllSidesRating activeCategory={params.value} single={true} />
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    field: "charityNavigatorSource",
    headerName: "Charity Navigator",
    ...(persistedWidths["charityNavigatorSource"]
      ? { flex: 0, width: persistedWidths["charityNavigatorSource"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Typography
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value ? `${params.value}%` : "-"}
      </Typography>
    ),
  },
  {
    field: "isSummaryAvailable_ysn",
    headerName: "Summary",
    ...(persistedWidths["isSummaryAvailable_ysn"]
      ? { flex: 0, width: persistedWidths["isSummaryAvailable_ysn"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "summary");
        }}
      >
        <SvgIcon
          component={summarizerLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
  {
    field: "notesCount_cnt",
    headerName: "Notes",
    ...(persistedWidths["notesCount_cnt"]
      ? { flex: 0, width: persistedWidths["notesCount_cnt"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Typography
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "isCitationAvailable_ysn",
    headerName: "Citation",
    ...(persistedWidths["isCitationAvailable_ysn"]
      ? { flex: 0, width: persistedWidths["isCitationAvailable_ysn"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "citation");
        }}
      >
        <SvgIcon
          component={citationLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
  {
    field: "isReadingLevelAvailable_ysn",
    headerName: "Leveling",
    ...(persistedWidths["isReadingLevelAvailable_ysn"]
      ? { flex: 0, width: persistedWidths["isReadingLevelAvailable_ysn"] }
      : commonWidth !== null
      ? { flex: 0, width: commonWidth }
      : { flex: 1 }),
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "readinglevel");
        }}
      >
        <SvgIcon
          component={readingLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
    ],
    [persistedWidths, theme, dispatch]
  );

  const scrollToLeft = () => {
    const virtualScroller = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    );
    if (virtualScroller) {
      virtualScroller.scrollLeft = 0;
    }
  };

  const clearWidthModel =  () => {
    scrollToLeft();
    writeStorage(`recent-columnWidths-${user.userId}`, {});
    setCommonWidth(null);
    setPersistedWidths({});
    columnWidthsRef.current = {};
  }

  return (
    <Box ref={containerRef} sx={{ width: "100%" }}>
      <ColumnsSection
        visibilityModel={visibilityModel}
        setVisibilityModel={setVisibilityModel}
        columns={columns}
        label={`recent-columnvisibility-${user.userId}`}
        clearWidthModel={clearWidthModel}
        widthModal={persistedWidths}
      />
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={recentCollectionSource}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        onColumnWidthChange={handleColumnResize}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        getRowId={(row) => row.collectionSourceID_ids}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
