import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlayerFactory } from "boclips-player";
import "boclips-player/dist/main.css";
import { setBoclipsPopup } from "context/features/dataSlice";
import useBoclipsSearch from "../hooks/use-boclips-search.hook";
import { useIsMobile } from "hooks/is-mobile.hook";
import { searchAPI } from "services/apis.service";

const BoclipsPlayerDialog = () => {
  const { boclipsUser } = useSelector((state) => state.auth);
  const { boclipsPopup, boClipsVideoId } = useSelector((state) => state.data);
  const { fetchBoclipsToken, fetchBoclipsDataById, boclipsCard } =
    useBoclipsSearch(
      () => {},
      "",
      () => {}
    );
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const maxWidth = useMemo(() => (isMobile ? "95vw" : "60vw"), [isMobile]);

  const handleClose = () => {
    dispatch(setBoclipsPopup(false));
  };

  const tokenFactory = async () => {
    if (isTokenExpired(boclipsUser.accessToken)) {
      const refreshedToken = await fetchBoclipsToken();
      return refreshedToken;
    }
    return boclipsUser.accessToken;
  };

  const isTokenExpired = (token) => {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.exp * 1000 < Date.now();
  };

  useEffect(() => {
    let player;

    const initializePlayer = async () => {
      try {
        const playerContainer = document.getElementById(
          "boclips-player-container"
        );

        if (!playerContainer) {
          throw new Error("Player container not found");
        }

        playerContainer.innerHTML = "";

        player = PlayerFactory.get(playerContainer, {
          api: { tokenFactory },
        });

        await player.loadVideo(
          `${searchAPI.boclipsLoadVideo}/${boClipsVideoId}`
        );

        console.log("Video loaded successfully");
      } catch (error) {
        console.error("Error initializing or loading video:", error);
      }
    };

    if (boclipsPopup && boclipsUser?.accessToken && boClipsVideoId) {
      setTimeout(initializePlayer, 100);
    }

    return () => {
      if (player) {
        player.destroy();
        console.log("Player destroyed");
      }
    };
  }, [boclipsPopup, boClipsVideoId]);

  useEffect(() => {
    if(boClipsVideoId){
      fetchBoclipsDataById(boClipsVideoId);
    }
  }, [boClipsVideoId]);

  return (
    <Dialog
      open={boclipsPopup}
      onClose={handleClose}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          maxWidth: isMobile ? null : maxWidth,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pr: 10,
          minHeight: "3em",
        }}
      >
        {boclipsCard.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseOutlined />
      </IconButton>
      <Box
        id="boclips-player-container"
        sx={{
          width: "100%",
          height: isMobile ? "53.44vw" : "33.75vw",
          backgroundColor: "black",
        }}
      />
    </Dialog>
  );
};

export default BoclipsPlayerDialog;
