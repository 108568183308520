import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CategoryService } from "features/SourceSearch";
import { biasRating } from "data/biasRatingLabel";
import AcademiaIconLink from "assets/icons/icon_academia.svg";
import GlobalNewsIconLink from "assets/icons/icon_global_news.svg";
import InterGovernmentalIconLink from "assets/icons/icon_intergovernmental.svg";
import OtherSourcesIconLink from "assets/icons/icon_other_sources.svg";
import PeriodicalsSpecialtyIconLink from "assets/icons/icon_periodicals_specialty.svg";
import ThinkTankIconLink from "assets/icons/icon_think_tanks.svg";
import UsGovernmentIconLink from "assets/icons/icon_usgov.svg";
import { axiosInstance } from "lib/axios";
import { useDispatch } from "react-redux";
import { setCategories } from "context/features/dataSlice";

export default function useCategoryData(setLoading) {
  const { categories } = useSelector(
    (state) => state.data
  );
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { source, search } = useParams();
  const { getCategories, updateCategory, getDynamicCategories } =
    CategoryService(axiosInstance);
  const [categoriesCopy, setCategoriesCopy] = useState(categories);

  const getDynamicCategoriesFromUrl = async (
    categoriesUrl,
    setCategorySorted
  ) => {
    try {
      const data = await getDynamicCategories(search, categoriesUrl);
      dispatch(setCategories(data));
      setCategorySorted(true);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchNewsCategoryData = async () => {
    try {
      setLoading(true);
      if (source === "rating") {
        const data = biasRating;
        dispatch(setCategories(data));
        setCategoriesCopy(data);
      } else {
        const data = await getCategories(user.userId);
        dispatch(setCategories(data));
        setCategoriesCopy(data);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  async function updateCategoryList(data) {
    try {
      setCategoriesCopy(data);
      await updateCategory(data);
      dispatch(setCategories(data));
    } catch (e) {
      console.error(e);
    }
  }

  function getCategoryIconLink(categoryName) {
    switch (categoryName) {
      case "Periodicals & Specialty News":
        return PeriodicalsSpecialtyIconLink;
      case "Think Tanks":
        return ThinkTankIconLink;
      case "Global News":
        return GlobalNewsIconLink;
      case "Academia":
        return AcademiaIconLink;
      case "Other Selected Sources":
        return OtherSourcesIconLink;
      case "US Government":
        return UsGovernmentIconLink;
      case "Intergovernmental Organizations":
        return InterGovernmentalIconLink;
      default:
        return "";
    }
  }

  return {
    categories,
    getCategoryIconLink,
    updateCategoryList,
    categoriesCopy,
    fetchNewsCategoryData,
    getDynamicCategoriesFromUrl,
  };
}
