import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  Stack,
  Divider,
  Button,
  useTheme,
  Grid,
} from "@mui/material";
import PageTitle from "components/layout/PageTitle";
import LensOutlinedIcon from "@mui/icons-material/LensOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { addFeedCategoriesForUser } from "../services/news-feed.services";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { authAPI } from "services/apis.service";
import { setIsCategoriesSelected } from "context/features/dataSlice";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { toast } from "react-toastify";

const WhatsYourPulse = ({ feedCategories, pageTitle = true }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.auth);
  const { isCategoriesSelected } = useSelector((state) => state.data);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const theme = useTheme();

  const handleCategoryClick = (categoryId) => {
    if (categoryId === 1) {
      if (selectedCategories.includes(1)) {
        setSelectedCategories([]);
      } else {
        setSelectedCategories(
          feedCategories.map((category) => category.categoryId)
        );
      }
    } else {
      setSelectedCategories((prevSelected) => {
        if (prevSelected.includes(categoryId)) {
          const newSelected = prevSelected.filter((id) => id !== categoryId);
          if (newSelected.includes(1)) {
            return newSelected.filter((id) => id !== 1);
          }
          return newSelected;
        } else {
          const newSelected = [...prevSelected, categoryId];
          if (
            newSelected.length === feedCategories.length - 1 &&
            !newSelected.includes(1)
          ) {
            return [...newSelected, 1];
          }
          return newSelected;
        }
      });
    }
  };

  const updateIsCategorySelected = async () => {
    try {
      const { data } = await axios.get(
        `${authAPI.getUserDetails}?userId=${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(setIsCategoriesSelected(data.isCategoriesSelected));
    } catch (error) {
      console.error("Failed to fetch user details", error);
    }
  };

  const onSave = async () => {
    try {
      await addFeedCategoriesForUser(
        user.callingUserId,
        user.userId,
        selectedCategories
      );
      toast.success("Pulse Topics updated successfully!");
      await updateIsCategorySelected();
    } catch (error) {
      console.error("Failed to save categories", error);
    }
  };

  useEffect(() => {
    if (feedCategories.length) {
      const selected = feedCategories
        .filter((category) => {
          if (category.isSelected) {
            return true;
          }
        })
        .map((category) => category.categoryId);
      setSelectedCategories(selected);
    }
  }, [feedCategories]);

  return (
    <Grid sx={{ mb: isMobile ? "5vh" : "auto" }} container>
      <Grid item xs={12} md={8}>
        {pageTitle && <PageTitle>What's your Pulse?</PageTitle>}
        <Typography pt={3}>
          Keep up with trending articles on a variety of topics. Start by
          telling us what interests you most. (Select at least 2.)
        </Typography>
        <Stack direction="row" py={4} sx={{ flexWrap: "wrap" }}>
          {feedCategories.map((category) => (
            <Chip
              icon={
                !selectedCategories.includes(category.categoryId) ? (
                  <LensOutlinedIcon
                    size={"small"}
                    sx={{ color: "grey", fontSize: 16 }}
                  />
                ) : (
                  <DoneOutlinedIcon sx={{ color: "black", fontSize: 18 }} />
                )
              }
              key={category.categoryId}
              label={category.categoryName}
              variant={
                selectedCategories.includes(category.categoryId)
                  ? "default"
                  : "outlined"
              }
              onClick={() => handleCategoryClick(category.categoryId)}
              sx={{
                backgroundColor: selectedCategories.includes(
                  category.categoryId
                )
                  ? theme.palette.secondary.main
                  : "inherit",
              }}
            />
          ))}
        </Stack>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            disabled={selectedCategories.length < 2}
            onClick={() => onSave(selectedCategories)}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatsYourPulse;
