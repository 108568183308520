import amplitude from "amplitude-js";
import { amplitudeKey } from "config";
import { logGAEvent } from "./googleanalytics.service";
import { postDataToEventHub } from "./eventhub.service";

amplitude.getInstance().init(amplitudeKey);

function initAmplitude(userId) {
  amplitude.getInstance().setUserId(userId);
}

function logEvent(eventName, eventProperties) {
  postDataToEventHub(eventName, eventProperties);
  amplitude.getInstance().logEvent(eventName, eventProperties);
  logGAEvent(eventName, eventProperties);
}

export { initAmplitude, logEvent };
