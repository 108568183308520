import dayjs from "lib/dayjs";

export const getFormattedDate = (
  datetime,
  diff = "day",
  span = 6,
  past = true,
  noSuffix = true
) => {
  if (!datetime) return "NA";
  const date = dayjs(datetime);
  if (!date.isValid()) return "Invalid date";

  const now = dayjs().utc();
  const between = Math.abs(now.diff(date, diff));

  if (between < span) {
    return past
      ? date.local().fromNow(noSuffix)
      : now.local().to(date, noSuffix);
  }
  return date.local().format("ll");
};

export const getCurrentTime = () => {
  const today = dayjs();
  return today.local().format("hh:mm A");
};
export const getCurrentDate = () => {
  const today = dayjs();
  return today.locale().format("MM/DD/YYYY");
};

export function subtractYearsAndGetFormattedDate(date, yearsToSubtract) {
  const adjustedDate = new Date(date);
  adjustedDate.setFullYear(date.getFullYear() - yearsToSubtract);

  const formattedDate = adjustedDate.toISOString().split("T")[0];
  return formattedDate;
}

export const getRegularDate = (datetime) => {
  if (!datetime) return "";

  const date = new Date(datetime);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const citationDate = (datetime) => {
  if (!datetime) return "";

  const date = new Date(datetime);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDuration = (duration) => {  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  const hours = match[1] ? parseInt(match[1].replace("H", ""), 10) : 0;
  const minutes = match[2] ? parseInt(match[2].replace("M", ""), 10) : 0;
  const seconds = match[3] ? parseInt(match[3].replace("S", ""), 10) : 0;

  let timeString = "";
  if (hours > 0) {
    timeString += `${hours}:`;
  }
  timeString += `${minutes.toString().padStart(2, "0")}:`;
  timeString += `${seconds.toString().padStart(2, "0")}`;

  return timeString;
};
