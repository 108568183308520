import { useParams } from "react-router-dom";
import { BooksCard } from "./books-card";
import { CategoryCard } from "./category-card";
import { WikiCard } from "./wikipedia-card";
import { AcademiaCard } from "./academia-card";
import { BoclipsCard } from "./boclips-card";

const getCardComponent = (categoryType, academiaSearch, currentCategory) => {
  if (academiaSearch) {
    return AcademiaCard;
  }
  switch (categoryType) {
    case "books":
      return BooksCard;
    case "wiki":
      return WikiCard;
    case "category":
    default:
      if (currentCategory === "boclips"){
        return BoclipsCard;
      } 
      return CategoryCard;
  }
};

export default getCardComponent;
