import { useEffect, useState } from "react";
import { fetchCombinedSearch } from "../services/news.service";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFilterDateValueKeys } from "utils/date-filter.util";
import { useSourceSearch } from "features/SourceSearch";
import { useDispatch } from "react-redux";
import { setCategories } from "context/features/dataSlice";
import { biasRating } from "data/biasRatingLabel";

const useNewsData = (
  setGlobalLoading,
  query,
  categoriesCopy,
  setLoadingStates
) => {
  const [combinedSearch, setCombinedSearch] = useState([]);
  const dispatch = useDispatch();

  const { currentTimeframe, startDate, endDate, currentSource } =
    useSourceSearch();
  const {source} = useParams();
  const { categories } = useSelector((state) => state.data);

  const filteredDateValue = getFilterDateValueKeys(
    currentTimeframe,
    startDate,
    endDate
  );

  const fetchCombinedSearchData = async () => {
    setGlobalLoading(true);
    setCombinedSearch([]);
    setLoadingStates({});

    try {
      const tempCategories = source === "rating" ? biasRating : categories;
      const visibleCategoryNames = tempCategories.reduce((acc, category) => {
        if (!category.isHidden_ysn) {
          acc.push(category.categoryName_chr);
        }
        return acc;
      }, []);

      visibleCategoryNames.forEach((category) => {
        setLoadingStates((prev) => ({ ...prev, [category]: true }));
      });

    if (
      source !== "rating" &&
      visibleCategoryNames &&
      visibleCategoryNames[0]?.includes("Left & Lean Left")
    ) {
      return;
    }

      const combinedSearch = await fetchCombinedSearch(
        query,
        filteredDateValue,
        visibleCategoryNames
      );

      setCombinedSearch(combinedSearch.results);

      visibleCategoryNames.forEach((category) => {
        setLoadingStates((prev) => ({
          ...prev,
          [category]: false,
        }));
      });

      if(source !== "rating")
      dispatch(setCategories(combinedSearch.categories));
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    if (query && categoriesCopy.length > 0) fetchCombinedSearchData();
  }, [query, filteredDateValue, categoriesCopy, source]);

  return { combinedSearch };
};

export default useNewsData;
