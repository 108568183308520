import useInfiniteScrollBoclips from "../hooks/use-infinite-boclips-scroll.hook";

const BoclipsScrollComponent = ({
  offset,
  setLoading,
  categoryData,
  setcategoryData,
  setoffset,
  setShouldLoad,
  setHasMore,
  setScrollLoading,
}) => {
  const { error } = useInfiniteScrollBoclips(
    offset,
    setLoading,
    setcategoryData,
    setHasMore
  );
};

export default BoclipsScrollComponent;
