import { filter, findIndex, forEach, map, orderBy } from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";
import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";

import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";
import { useVideos, VideoCard, VideoDialog } from "features/Support";
import categories from "features/Support/data/categories.json";
import { useIsMobile } from "hooks/is-mobile.hook";

export default function SupportPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { getVimeoUsersVideosMetadata } = useVideos();
  const [activeVideo, setActiveVideo] = useState(null);
  const [videos, setVideos] = useState(new Map());
  const [categoryMap, setCategoryMap] = useState(new Map());
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(0);

  const scrollRef = useRef(null);

  const isLoading = useMemo(() => {
    if (loading > 0) {
      return true;
    } else {
      return false;
    }
  }, [loading]);

  function findHashindex(hash) {
    if (hash.length > -0) {
      const index = findIndex(
        categories,
        (cat) => cat.key === hash.substring(1)
      );
      return index > -1 ? index : 0;
    }
    return 0;
  }

  const tab = useMemo(() => {
    return findHashindex(location.hash);
  }, [location]);

  function handleOpen(video) {
    setActiveVideo(video);
  }

  function handleClose() {
    setActiveVideo(null);
  }

  function handleSpy(id) {
    console.log(id);
    navigate(`#${id}`);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(loading + 1);
      try {
        const rawVideos = await getVimeoUsersVideosMetadata("215554981");
        const videos = new Map();
        const categoryMap = new Map();
        forEach(
          orderBy(
            filter(rawVideos, (video) => video.tags.includes("help")),
            ["dateOrder"],
            ["desc"]
          ),
          (video) => {
            for (let i = 0; i < video.tags.length; i++) {
              if (video.tags[i] !== "help") {
                if (categoryMap.has(video.tags[i])) {
                  categoryMap.set(video.tags[i], [
                    ...categoryMap.get(video.tags[i]),
                    video.id,
                  ]);
                } else {
                  categoryMap.set(video.tags[i], [video.id]);
                }
              }
            }
            videos.set(video.id, video);
          }
        );
        setVideos(videos);
        setCategoryMap(categoryMap);
      } catch (error) {
        console.error(error);
        const { response } = error;
        if (response != null) {
          setErrorMessage({
            status: 400,
            message: (
              <Typography>
                Unable to get videos from Vimeo. Please check back later.
              </Typography>
            ),
          });
        } else {
          setErrorMessage({
            status: 500,
            message: (
              <Typography>
                There was an error getting the videos from Vimeo. Please check
                back later.
              </Typography>
            ),
          });
        }
      } finally {
        setLoading(loading <= 0 ? 0 : loading - 1);
      }
    }
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        rowGap: 2,
        ...fillParent,
      }}
    >
      <Stack
        direction="column"
        sx={({ palette, zIndex }) => ({
          position: { xs: "sticky", md: "static" },
          top: 0,
          zIndex: zIndex.appBar,
          backgroundColor: palette.background.default,
        })}
      >
        <PageTitle>Help Videos</PageTitle>
        {errorMessage != null && (
          <Alert severity="error">
            <AlertTitle>
              {errorMessage.title != null
                ? errorMessage.title
                : "Problem getting help videos"}
            </AlertTitle>
            {errorMessage.message}
          </Alert>
        )}
        <Tabs
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ maxWidth: "100vw" }}
        >
          {map(categories, (cat) => (
            <Tab
              LinkComponent={NavLink}
              to={`#${cat.key}`}
              key={`${cat.key}-nav-link`}
              label={cat.title}
              data-to-scrollspy-id={cat.key}
            />
          ))}
        </Tabs>
      </Stack>
      <Box sx={{ position: "relative", maxWidth: "100vw" }}>
        <Box
          sx={{
            ...fillParent,
            ...scrollingBox,
            ...footerFlex,
          }}
          ref={scrollRef}
        >
          <ScrollSpy
            parentScrollContainerRef={isMobile ? null : scrollRef}
            onUpdateCallback={handleSpy}
            updateHistoryStack={false}
            offsetTop={isMobile ? 170 : 0}
            offsetBottom={isMobile ? 60 : 0}
          >
            {map(categories, (cat) => (
              <Stack key={`video-category-${cat.key}`} mt={2} id={cat.key}>
                <Typography variant="h4">{cat.title}</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(300px, 1fr))",
                    gap: 2,
                  }}
                >
                  {isLoading &&
                    Array.from(Array(3)).map((value, index) => (
                      <VideoCard video={null} key={`${cat.key}-${index}`} />
                    ))}
                  {!isLoading &&
                    categoryMap.has(cat.key) &&
                    map(categoryMap.get(cat.key), (video) => (
                      <VideoCard
                        video={videos.get(video)}
                        handleAction={handleOpen}
                        key={video}
                      />
                    ))}
                </Box>
              </Stack>
            ))}
            {activeVideo != null && (
              <VideoDialog
                open={activeVideo != null}
                handleClose={handleClose}
                title={activeVideo.title}
                id={activeVideo.id}
              />
            )}
          </ScrollSpy>
        </Box>
      </Box>

      <Footer sx={{ pl: 0, pr: 2 }} />
    </Box>
  );
}
