import React, { useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { getFormattedDate } from "utils/time.util";
import { MoreHoriz } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLocalStorage, { writeStorage } from "@rehooks/local-storage";
import ColumnsSection from "features/Collections/components/ColumnSection";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

function Table({ publicCollections, setCurrentPublicCollection }) {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const defaultVisibilityModel = {
    lastUpdatedDateTime_dtm: true,
    collectionName_chr: true,
    userName_chr: true,
    publicCollectionSource_Count_int: true,
  };

  const { user } = useSelector((state) => state.auth);

  const [visibilityModel, setVisibilityModel] = useLocalStorage(
    `public-columnvisibility-${user.userId}`,
    defaultVisibilityModel
  );

  const [persistedWidths, setPersistedWidths] = useLocalStorage(
    `public-columnWidths-${user.userId}`,
    {}
  );

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [commonWidth, setCommonWidth] = useState(null);

  const columnWidthsRef = useRef(persistedWidths);

  const computeCommonWidth = () => {
    const element = containerRef.current;
    let width = element ? element.clientWidth : 0;  

    const visibleFields = Object.keys(visibilityModel).filter(
      (field) => visibilityModel[field]
    );
    const unsetCount = visibleFields.length;
    const newCommonWidth = unsetCount > 0 ? Math.floor(width / unsetCount) : 0;
    setCommonWidth(newCommonWidth);
  };

  const debouncedUpdateColumnWidths = () => {
        writeStorage(
          `public-columnWidths-${user.userId}`,
          columnWidthsRef.current
        );
        setPersistedWidths(columnWidthsRef.current);
        computeCommonWidth();
      };

  const handleColumnResize = (params) => {
    columnWidthsRef.current = {
      ...columnWidthsRef.current,
      [params.colDef.field]: params.width,
    };
    debouncedUpdateColumnWidths();
  };

  useEffect(() => {
    if (Object.keys(persistedWidths).length === 0) {
      setCommonWidth(null);
    }
  }, [persistedWidths]);

  useEffect(() => {
    if (!visibilityModel || Object.keys(visibilityModel).length === 0) {
      setVisibilityModel(defaultVisibilityModel);
      writeStorage(
        `public-columnvisibility-${user.userId}`,
        defaultVisibilityModel
      );
      apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
    } else {
      apiRef.current.setColumnVisibilityModel(visibilityModel);
    }
  }, []);

  const handleVisibilityChange = (newVisibilityModel) => {
    const updatedVisibility = {
      ...visibilityModel,
      ...newVisibilityModel,
    };
    setVisibilityModel(updatedVisibility);
    writeStorage(`public-columnvisibility-${user.userId}`, updatedVisibility);
  };

  const columns = useMemo(
  () => [
    {
      field: "lastUpdatedDateTime_dtm",
      headerName: "Updated",
      sortable: true,
      type: "date",
      ...(persistedWidths["lastUpdatedDateTime_dtm"]
        ? { flex: 0, width: persistedWidths["lastUpdatedDateTime_dtm"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
        valueGetter: (value, row, column, apiRef) => {
          return value ? new Date(value) : null;
        },
      renderCell: (params) => (
        <Typography variant="inherit" noWrap>
          {getFormattedDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "collectionName_chr",
      headerName: "Collection Name",
      sortable: true,
      ...(persistedWidths["collectionName_chr"]
        ? { flex: 0, width: persistedWidths["collectionName_chr"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 2 }),
      renderCell: (params) => (
        <Typography
          onClick={() => {
            setCurrentPublicCollection(params.row);
            navigate(
              `/collections/public/${params.row.publicCollectionID_chr}`
            );
          }}
          variant="inherit"
          noWrap
          sx={{ fontSize: "1.4rem" }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "userName_chr",
      headerName: "Owner",
      sortable: false,
      ...(persistedWidths["userName_chr"]
        ? { flex: 0, width: persistedWidths["userName_chr"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Typography
          noWrap
          variant="inherit"
          fontSize="1.4rem"
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "publicCollectionSource_Count_int",
      headerName: "Source Count",
      sortable: true,
      ...(persistedWidths["publicCollectionSource_Count_int"]
        ? {
            flex: 0,
            width: persistedWidths["publicCollectionSource_Count_int"],
          }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Typography
          fontSize="1.4rem"
          variant="inherit"
        >
          {params.value}
        </Typography>
      ),
    },
  ],
    [persistedWidths, commonWidth, theme]
  );

  const scrollToLeft = () => {
    const virtualScroller = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    );
    if (virtualScroller) {
      virtualScroller.scrollLeft = 0;
    }
  };

  const clearWidthModel = () => {
    scrollToLeft();
    writeStorage(`public-columnWidths-${user.userId}`, {});
    setCommonWidth(null);
    setPersistedWidths({});
    columnWidthsRef.current = {};
  };

  return (
    <Box ref={containerRef} sx={{ width: "100%" }}>
      <ColumnsSection
        visibilityModel={visibilityModel}
        setVisibilityModel={setVisibilityModel}
        columns={columns}
        label={`public-columnvisibility-${user.userId}`}
        clearWidthModel={clearWidthModel}
        widthModal={persistedWidths}
      />
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={publicCollections}
        columns={columns}
        onColumnWidthChange={handleColumnResize}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          margin: "0 auto",
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer"
          },
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        getRowId={(row) => row.publicCollectionID_chr}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        hideFooterSelectedRowCount
        onRowClick={(params) => {
          setCurrentPublicCollection(params.row);
          navigate(`/collections/public/${params.row.publicCollectionID_chr}`);
        }}
      />
    </Box>
  );
}

export default Table;
