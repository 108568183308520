import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, SvgIcon, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { getRegularDate } from "utils/time.util";
import {
  setBoclipsPopup,
  setBoclipsVideoId,
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import ColumnsSection from "./ColumnSection";
import getRefreshTOkenWashingtonPost from "hooks/use-washinghton-token";
import { debounce } from "lodash";

const PublicCollectionTable = ({ rows }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const { id } = useParams();

  const defaultVisibilityModel = {
    createdDateTime_dtm: true,
    title_chr: true,
    sourceName_chr: true,
    sourcePublishedDateTime_dtm: true,
    score: false,
    mediaBiasRating: false,
    charityNavigatorSource: false,
  };

  const { user } = useSelector((state) => state.auth);

  const [visibilityModel, setVisibilityModel] = useLocalStorage(
    `public-table-columnvisibility-${user.userId}`,
    defaultVisibilityModel
  );

  const [persistedWidths, setPersistedWidths] = useLocalStorage(
    `public-table-columnWidths-${user.userId}`,
    {}
  );

  const [commonWidth, setCommonWidth] = useState(null);

  const columnWidthsRef = useRef(persistedWidths);
  const containerRef = useRef(null);

  const computeCommonWidth = () => {
  const element = containerRef.current;
  let width = element ? element.clientWidth : 0;  

  const visibleFields = Object.keys(visibilityModel).filter(
    (field) => visibilityModel[field]
  );
  const unsetCount = visibleFields.length;
  const newCommonWidth = unsetCount > 0 ? Math.floor(width / unsetCount) : 0;
  setCommonWidth(newCommonWidth);
  };

  const debouncedUpdateColumnWidths = () => {
    writeStorage(
      `public-table-columnWidths-${user.userId}`,
      columnWidthsRef.current
    );
    setPersistedWidths(columnWidthsRef.current);
    computeCommonWidth();
  }

  const handleColumnResize = (params) => {
    columnWidthsRef.current = {
      ...columnWidthsRef.current,
      [params.colDef.field]: params.width,
    };
    debouncedUpdateColumnWidths();
  };

  useEffect(() => {
  if (Object.keys(persistedWidths).length === 0) {
    setCommonWidth(null);
  }
  }, [persistedWidths]);

  useEffect(() => {
    if (!visibilityModel || Object.keys(visibilityModel).length === 0) {
      setVisibilityModel(defaultVisibilityModel);
      writeStorage(
        `public-table-columnvisibility-${user.userId}`,
        defaultVisibilityModel
      );
      apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
    } else {
      apiRef.current.setColumnVisibilityModel(visibilityModel);
    }
  }, []);

  const handleSidebarOpen = (item, option) => {
    dispatch(setCollectionSourceId(item.collectionSourceID_ids));
    dispatch(setLevel(""));
    dispatch(setSelectedBookmarkOption(option));
    dispatch(setReadingLevelAvailable(item.isReadingLevelAvailable_ysn));
    dispatch(
      setSummaryInfoData({
        publisher: item.sourceName_chr,
        title: item.title_chr,
        published_date: item.sourcePublishedDateTime_dtm,
        url: item.sourceUrl_chr,
      })
    );
    dispatch(setCollectionId(item.collectionID_lng));
    dispatch(setIsCitationAvailable(item.isCitationAvailable_ysn));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  const handleVisibilityChange = (newVisibilityModel) => {
    const updatedVisibility = {
      ...visibilityModel,
      ...newVisibilityModel,
    };
    setVisibilityModel(updatedVisibility);
    writeStorage(
      `public-table-columnvisibility-${user.userId}`,
      updatedVisibility
    );
  };

  const columns = useMemo(
    () => [
    {
      field: "createdDateTime_dtm",
      headerName: "Added",
      type: "date",
      ...(persistedWidths["createdDateTime_dtm"]
        ? { flex: 0, width: persistedWidths["createdDateTime_dtm"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      valueGetter: (value, row, column, apiRef) => {
        return value ? new Date(value) : null;
      },
      renderCell: (params) => {
        const date = params.value ? getRegularDate(params.value) : "-";
        return (
          <Typography
            onClick={() => handleSidebarOpen(params.row, "notes")}
            noWrap
            color={theme.palette.text.secondary}
            variant="inherit"
            sx={{
              cursor: "pointer",
            }}
          >
            {date || "-"}
          </Typography>
        );
      },
    },
    {
      field: "title_chr",
      headerName: "Title",
      ...(persistedWidths["title_chr"]
        ? { flex: 0, width: persistedWidths["title_chr"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 2 }),
      renderCell: (params) => {
        const { sourceUrl_chr } = params.row;
        const handleLinkClick = async (event) => {
          event.preventDefault();
          let resolvedUrl = sourceUrl_chr;
          if (resolvedUrl.includes("boclips.com")) {
            const id = resolvedUrl.split("/").pop();
            dispatch(setBoclipsVideoId(id));
            dispatch(setBoclipsPopup(true));
            return;
          }
          if (sourceUrl_chr && sourceUrl_chr.includes("washingtonpost.com")) {
            resolvedUrl = await getRefreshTOkenWashingtonPost(sourceUrl_chr);
          }
          if (resolvedUrl.includes("foreignpolicy.com")) {
            resolvedUrl += "?auth=sooth";
          }
          window.open(resolvedUrl, "_blank");
        };
        return sourceUrl_chr ? (
          <Typography
            variant="inherit"
            noWrap
            sx={{
              fontSize: "1.3rem",
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={handleLinkClick}
          >
            {params.value || "-"}
          </Typography>
        ) : (
          <Typography variant="inherit" noWrap sx={{ fontSize: "1.3rem" }}>
            {params.value || "-"}
          </Typography>
        );
      },
    },
    {
      field: "sourceName_chr",
      headerName: "Source",
      ...(persistedWidths["sourceName_chr"]
        ? { flex: 0, width: persistedWidths["sourceName_chr"] }
          : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Typography
          fontSize={"1.3rem"}
          variant="inherit"
          noWrap
          fontWeight={"bold"}
          color={"#6c6c6c"}
          onClick={() => handleSidebarOpen(params.row, "notes")}
          sx={{
            cursor: "pointer",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "sourcePublishedDateTime_dtm",
      headerName: "Pub Date",
      ...(persistedWidths["sourcePublishedDateTime_dtm"]
        ? { flex: 0, width: persistedWidths["sourcePublishedDateTime_dtm"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      type: "date",
      valueGetter: (value, row, column, apiRef) => {
        return value ? new Date(value) : null;
      },
      renderCell: (params) => {
        const date = params.value ? getRegularDate(params.value) : "-";
        return (
          <Typography
            noWrap={true}
            onClick={() => handleSidebarOpen(params.row, "notes")}
            variant="inherit"
            color={"#6c6c6c"}
            sx={{
              cursor: "pointer",
            }}
          >
            {date || "-"}
          </Typography>
        );
      },
    },
    {
      field: "score",
      headerName: "NewsGuard",
      ...(persistedWidths["score"]
        ? { flex: 0, width: persistedWidths["score"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
        >
          {params.value ? (
            <NewsguardScore score={Number(params.value)} size="small" />
          ) : (
            "-"
          )}
        </Box>
      ),
    },
    {
      field: "mediaBiasRating",
      headerName: "AllSides",
      ...(persistedWidths["mediaBiasRating"]
        ? { flex: 0, width: persistedWidths["mediaBiasRating"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center", minHeight: "100%" }}
        >
          {params.value ? (
            <AllSidesRating activeCategory={params.value} single={true} />
          ) : (
            "-"
          )}
        </Box>
      ),
    },
    {
      field: "charityNavigatorSource",
      headerName: "Charity Navigator",
      ...(persistedWidths["charityNavigatorSource"]
        ? { flex: 0, width: persistedWidths["charityNavigatorSource"] }
        : commonWidth !== null
        ? { flex: 0, width: commonWidth }
        : { flex: 1 }),
      renderCell: (params) => (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value ? `${params.value}%` : "-"}
        </Typography>
      ),
    },
    ],
    [persistedWidths, theme, dispatch]
  );

  const scrollToLeft = () => {
    const virtualScroller = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    );
    if (virtualScroller) {
      virtualScroller.scrollLeft = 0;
    }
  };

  const clearWidthModel =  () => {
    scrollToLeft();
    writeStorage(`public-table-columnWidths-${user.userId}`, {});
    setCommonWidth(null);
    setPersistedWidths({});
    columnWidthsRef.current = {};
  }

  return (
    <Box ref={containerRef} sx={{ width: "100%" }}>
      <ColumnsSection
        visibilityModel={visibilityModel}
        setVisibilityModel={setVisibilityModel}
        columns={columns}
        label={`public-table-columnvisibility-${user.userId}`}
        clearWidthModel={clearWidthModel}
        widthModal={persistedWidths}
      />
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={rows}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        onColumnWidthChange={handleColumnResize}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        getRowId={(row) => row.publicCollectionSourceID_chr}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
};

export default PublicCollectionTable;
