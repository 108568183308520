import React from "react";
import useRelevantSearch from "../hooks/use-releveant-search.hook";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIsTab } from "hooks/is-tab.hook";

const RelevantSection = () => {
  const { relatedSearches } = useRelevantSearch();
  const isTab = useIsTab();

  const visibleSearches = isTab
    ? relatedSearches?.length > 0
      ? relatedSearches.slice(0, 3)
      : []
    : relatedSearches;

  if (visibleSearches.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mt: 2,
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <Typography noWrap sx={{ mr: 1 }}>
        Related Searches:
      </Typography>
      <Stack direction="row" gap={3}>
        {visibleSearches && visibleSearches.length > 0 && visibleSearches.map((relsearch, index) => (
          <Box
            key={relsearch}
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: isTab ? "20vw" : "30vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={relsearch}>
              <Link
                to={`/search/${relsearch}`}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              >
                {relsearch}
              </Link>
            </Tooltip>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default RelevantSection;
