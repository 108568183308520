import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";

import { useDispatch } from "react-redux";
import {
  setBoclipsPopup,
  setBoclipsVideoId,
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { logEvent } from "services/amplitude.service";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { formatDuration } from "utils/time.util";
import { searchAPI } from "services/apis.service";

export const BoclipsCard = ({ data={}, cardRef, category, query }) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  const openSidebar = () => {
    logEvent("open_bookmark_popup", {
      url: `${searchAPI.boclipsLoadVideo}/${data.id}`,
      query: query,
      entity: data.entityName,
      type: "books",
      category: category,
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });

    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    setIsHovered(false);
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: "Boclips",
        title: data.title,
        published_date: data.releasedOn,
        url: `${searchAPI.boclipsLoadVideo}/${data.id}`,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          cursor: "pointer",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
          pl: 1,
          pr: 1,
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            sx={{
              width: 18,
              height: 18,
              cursor: "pointer",
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="more"
          onClick={openSidebar}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const imageUrl = data?.thumbnail?.href
    ? data?.thumbnail?.href
    : defaultBackground;

  const handleTitleClick = () => {
    dispatch(setBoclipsVideoId(data.id));
    dispatch(setBoclipsPopup(true));
  };

  return (
    <React.Fragment>
      <Box
        ref={cardRef}
        sx={{
          display: "grid",
          gridTemplateAreas: `
      'imageSection titleNotesSection'
      'imageSection titleNotesSection'
      'divider divider'
    `,
          gridTemplateColumns: "2fr 3fr",
          gridTemplateRows: "auto 1fr auto",
          gap: 2,
          width: "100%",
          boxShadow: "none",
          mt: 1,
        }}
      >
        <Box
          sx={{
            gridArea: "imageSection",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            pr: 1,
            gap: 1,
            height: "100%",
          }}
        >
          <Box
            onClick={handleTitleClick}
            sx={{ position: "relative", height: "100%" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: 1,
              }}
            />
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "20vh",
                borderRadius: 1,
                cursor: "pointer",
              }}
              image={imageUrl}
              alt="No Image Found"
            />
            <PlayCircleIcon
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                fontSize: "3rem",
                color: "rgba(255, 255, 255, 0.8)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 1)",
                },
              }}
            />
            <Typography
              sx={{
                position: "absolute",
                bottom: 8,
                left: 8,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                padding: "2.5px 10px",
                fontSize: "1rem",
                borderRadius: "999px",
                color: "white",
              }}
            >
              {data?.playback?.duration &&
                formatDuration(data?.playback?.duration)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            gridArea: "titleNotesSection",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
            }}
          >
            <Typography
              variant="body4"
              onClick={handleTitleClick}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                lineHeight: "1.5em",
                flexShrink: 0,
                cursor: "pointer",
                "&:hover": {
                  color: "rgb(18,89,151)",
                },
              }}
            >
              {data.title}
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                maxHeight: { xs: "6vh", sm: "10vh" },
                lineHeight: { xs: "2vh", sm: "2vh" },
                width: "90%",
              }}
            >
              {data.releasedOn}
              {` - `}
              {data.description}
            </Typography>
            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                mt={1}
                sx={{
                  color: { xs: "black", sm: "grey" },
                  fontSize: { xs: "1.4rem" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  lineHeight: "2.5vh",
                  height: "2.5vh",
                }}
              >
                {data.createdBy}
              </Typography>
              <BookmarkOptions />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />
    </React.Fragment>
  );
};
