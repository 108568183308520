import React, { useState } from 'react'
import { customText } from '../data/levels';
import { useSelector } from 'react-redux';
import { fetchReadingLevel } from '../services/reading-level.service';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'services/amplitude.service';

const useReadingLevel = (
  setmarkdownSummary,
  setLoading,
  markdownSummary,
  sethtmlSummary,
  setDisable = () => {},
  setLevelLoaded = () => {}
) => {
  const { pathname } = useLocation();

  const { summaryInfoData } = useSelector((state) => state.data);
  const [changeTooltip, setChangeTooltip] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const setErrorSummaryMessage = () => {
    setErrorMessage(
      "We're sorry, we could not fetch the content of this article. Please copy and paste the article content here and click generate."
    );
    setmarkdownSummary("");
    sethtmlSummary("");
    setError(true);
    setLoading(false);
  };

  const getCompositionFromUrl = async (level, url) => {
    setDisable(true);
    const requestBody = { comp_level: level, url: url };
    setLoading(true);
    try {
      const data = await fetchReadingLevel(requestBody);
      if (
        data?.article_comp
          ?.toLowerCase()
          .includes("haven't provided an article") ||
        data?.article_comp?.toLowerCase().includes("please provide") ||
        data?.error?.includes("An error occurred while generating")
      ) {
        throw new Error("Invalid article or generation error");
      }
      setmarkdownSummary(data.article_comp);
      setLoading(false);
      setError(false);
      setLevelLoaded(true);
      setDisable(false);

      logEvent('create_reading_level', {
        url: url,
        type: 'url',
        level: level
      });
    } catch (e) {
      console.log(e);
      setErrorSummaryMessage();
    }
  };

  const getCompositionFromText = async (level, custom_text) => {
    setLoading(true);
    setDisable(true);
    const requestBody = { custom_text: custom_text, comp_level: level };
    try {
      const data = await fetchReadingLevel(requestBody);
      if (
        data?.article_comp
          ?.toLowerCase()
          .includes("haven't provided an article") ||
        data?.article_comp?.toLowerCase().includes("please provide") ||
        data?.error?.includes("An error occurred while generating")
      ) {
        throw new Error("Invalid article or generation error");
      }
      setmarkdownSummary(data.article_comp);      
      setLevelLoaded(true);
      setLoading(false);
      setError(false);
      setDisable(false);

      logEvent("create_reading_level", {
        url: summaryInfoData.url,
        type: "custom",
        level: level,
      });
    } catch (e) {
      console.log(e);
      setErrorSummaryMessage();
    }
  };

  const handleMouseOut = (event) => {
    setTimeout(() => {
      const fromElement = event.relatedTarget;
      const tooltipButton = document.getElementById("tooltipButton");

      if (!tooltipButton?.contains(fromElement)) {
        setChangeTooltip(false);
      }
    }, 1000);
  };

  const handleCopy = (level) => {
    const getSummaryData = summaryInfoData;
    const copiedData = `Publisher: ${getSummaryData.publisher} \nTitle: ${getSummaryData.title} \nPublished date: ${getSummaryData.published_date} \nLink: ${getSummaryData.url} \nReading Level: ${level} \n\Article Content: ${markdownSummary}`;
    navigator.clipboard.writeText(copiedData).catch((error) => {
      console.error("Unable to copy data to clipboard:", error);
    });
    setChangeTooltip(true);
  };

  return {
    getCompositionFromUrl,
    getCompositionFromText,
    handleCopy,
    handleMouseOut,
    changeTooltip,
    error,
    setError,
    errorMessage,
  };
};

export default useReadingLevel