import { Fragment } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

import { GroupUserTable, useGroup } from "features/Group";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { footerFlex, fillParent, scrollingBox } from "utils/base-styles";

import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { FeatureHelpVideo } from "features/Support";

export default function GroupUserManagement() {
  const isMobile = useIsMobile();

  const { group } = useGroup();

  return (
    group != null && (
      <Fragment>
        <Box
          sx={{
            ...fillParent,
            ...scrollingBox,
            ...footerFlex,
          }}
        >
          <Box
            sx={{
              display: "grid",
              minHeight: "100%",
              gridTemplateAreas: isMobile
                ? '"title" "tips" "buttons" "table" "footer"'
                : '"title buttons" "tips tips" "table table" "footer footer"',
              gridTemplateColumns: isMobile ? "1fr" : "1fr auto",
              gridTemplateRows: isMobile
                ? "auto auto 1fr auto"
                : "auto auto 1fr",
              gap: 2,
            }}
          >
            <PageTitle
              sx={{
                gridArea: "title",
              }}
            >
              User Management{" "}
              <FeatureHelpVideo
                showText
                videoId="1008256875"
                title="Tip: How to use User Management"
              />
            </PageTitle>
            <Box
              sx={{
                gridArea: "tips",
              }}
            >
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  sx={{
                    flexDirection: "row-reverse",
                    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
                      {
                        transform: "rotate(-90deg)",
                      },
                    // [`& .${accordionSummaryClasses.content}`]: {
                    //   marginLeft: 1,
                    // },
                  }}
                >
                  Helpful Tips
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" gap={1}>
                    <Typography>
                      This is where you can see all users that belong to your
                      Group Plan. You can delete users from this page. To add
                      users, see Add Users tab.
                    </Typography>
                    <Typography variant="subtitle1">Roles</Typography>
                    <Box component="ul" my={0}>
                      <Typography component="li">
                        You can assign other users to the role of Administrator.
                        All Administrators have access to Group Management tools
                        and the Subscriptions tab.
                      </Typography>
                      <Typography component="li">
                        Teacher and Student roles currently have identical
                        account permissions, however, in the future this may
                        change.
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1">Licenses</Typography>
                    <Box component="ul" my={0}>
                      <Typography component="li">
                        Please ensure that license assignments are accurate so
                        they are appropriately reflected your Group’s
                        Subscription.
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            <GroupUserTable
              sx={{
                gridArea: "table",
              }}
            />
            <Footer
              sx={{
                gridArea: "footer",
                px: 0,
              }}
            />
          </Box>
        </Box>
      </Fragment>
    )
  );
}
