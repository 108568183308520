import React, { useState } from "react";
import { customSummarize } from "../services/summarizer.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { logEvent } from "services/amplitude.service";

const useSummarize = (
  setLoading,
  summaryText,
  setSummaryText,
  setmarkdownSummary,
  markdownSummary,
  setDisable = () => {}
) => {
  const [changeTooltip, setChangeTooltip] = useState(false);

  const { summaryInfoData } = useSelector((state) => state.data);

  const handleGenerateSummary = async () => {
    if (summaryText.length < 10) {
      toast.error("Provided summary text is very small.");
      return;
    }

    setLoading(true);

    try {
      setDisable(true);
      const response = await customSummarize(summaryText);

      const generatedSummary = response.data.generated_summary;
      if (
        response.data.error === "index out of range in self" ||
        response.data.error?.length > 0
      ) {
        throw Error;
      }

      const bullets = Object.keys(generatedSummary)
        .filter((key) => key.startsWith("bullet"))
        .map((key) => `- ${generatedSummary[key]}`);
      const markdownList = bullets.join(" \n");
      setmarkdownSummary(markdownList);
      setDisable(false);

      logEvent('create_summary', {
        type: "custom",
        url: summaryInfoData.url
      });
    } catch (error) {
      console.error("Error generating summary:", error);
      toast.error(
        "An error occured while generating the summary. Please try again."
      );
      setSummaryText("");
    }
    setLoading(false);
  };

  const handleCopy = () => {
    const getSummaryData = summaryInfoData;
    const copiedData = `Summary: ${markdownSummary}`;
    navigator.clipboard.writeText(copiedData).catch((error) => {
      console.error("Unable to copy data to clipboard:", error);
    });
    setChangeTooltip(true);
  };

  const handleMouseOut = (event) => {
    setTimeout(() => {
      const fromElement = event.relatedTarget;
      const tooltipButton = document.getElementById("tooltipButton");

      if (!tooltipButton?.contains(fromElement)) {
        setChangeTooltip(false);
      }
    }, 1000);
  };

  return { handleGenerateSummary, handleCopy, handleMouseOut, changeTooltip };
};

export default useSummarize;
