import { axiosInstance } from "lib/axios"
import { searchAPI } from "services/apis.service"

export const getBoclipsSearch = async (search, timePeriod, pageNumber) => {
    const { data } = await axiosInstance.post(searchAPI.boclipsSearch, {
      searchTerm: search,
      timePeriod: timePeriod,
      pageNumber: pageNumber,
      pageSize: 21,
    });

    return data.data;
}

export const getBoclipsToken = async () => {
  const { data } = await axiosInstance.post(searchAPI.getBoclipsToken, {});
  return data.data;
};

export const getBoclipsDataById = async (id) => {
  const { data } = await axiosInstance.get(searchAPI.boclipsStream + `/${id}`);
  return data;
}