import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { writeStorage } from "@rehooks/local-storage";
import { useIsMobile } from "hooks/is-mobile.hook";

const ColumnsSection = ({
  visibilityModel,
  setVisibilityModel,
  columns,
  label,
  clearWidthModel,
  widthModal
}) => {
  const [anchorEl, setAnchorEl] = useState(null); 
  const open = Boolean(anchorEl);
  const isMobile = useIsMobile();

  const [showWidth, setShowWidth] = useState(true);

  useEffect(() => {
    setShowWidth(!widthModal || Object.keys(widthModal).length === 0);
  }, [widthModal]);
  

  const handleOpenManageColumns = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseManageColumns = () => {
    setAnchorEl(null);
  };

  const handleToggleColumnVisibility = (event, field) => {
    const newVisibilityModel = {
      ...visibilityModel,
      [field]: event.target.checked,
    };
    setVisibilityModel(newVisibilityModel);
    writeStorage(label, newVisibilityModel);
  };

  return (
    <Box sx={{ display: "flex", mb: 2 }}>
      <Stack direction="row" gap={0.5} alignItems="center">
        <IconButton onClick={handleOpenManageColumns}>
          <ViewWeekIcon />
        </IconButton>
        <Typography
          onClick={handleOpenManageColumns}
          sx={{ cursor: "pointer" }}
        >
          Columns
        </Typography>
        {!showWidth && (
          <Typography onClick={clearWidthModel} sx={{ ml: 4, cursor: "pointer" }}>
            Reset Width
          </Typography>
        )}
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseManageColumns}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: isMobile ? "bottom" : "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: isMobile ? "80vw" : "auto",
            maxHeight: "60vh",
            overflowY: "auto",
          },
        }}
      >
        {Object.keys(visibilityModel).map((field) => (
          <MenuItem key={field} sx={{ paddingY: 0, minHeight: "32px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibilityModel[field] ?? true}
                  onChange={(event) =>
                    handleToggleColumnVisibility(event, field)
                  }
                />
              }
              label={columns.find((col) => col.field === field)?.headerName}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ColumnsSection;
