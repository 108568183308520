import React, { useState } from "react";
import { Fab, Popover, Typography, Box, Button, Stack, Divider } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { QuestionMarkOutlined, CloseOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useIsMobile } from "hooks/is-mobile.hook";

const FloatingHelpButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      {/* Floating Help Button */}
      <Fab
        color="primary"
        aria-label="help"
        onClick={handleClick}
        style={{
          position: "fixed",
          bottom: isMobile ? 60: 40,
          right: 20,
        }}
      >
        {id ? <CloseOutlined /> : <QuestionMarkOutlined />}
      </Fab>

      {/* Popover for Help Content */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: 250,
        // }}
        transformOrigin={{
          vertical: 400,
          horizontal: 270,
        }}
      >
        <Box sx={{ px: 3, py: 2 }}>
          <Typography mb={2} variant="h4">
            Need help?
          </Typography>
          <Stack gap={2}>
            <Typography variant="body1">
              Live office hours Tuesdays 10am ET{" "}
            </Typography>
            <Link
              style={{ textDecoration: "none" }}
              to={`https://zoom.us/j/99895793119?pwd=3emJaaM6tWbGTg2sa7ewD8tw9OrQYW.1`}
              target="_blank"
            >
              <Button sx={{ width: "100%" }} variant="contained">
                JOIN
              </Button>
            </Link>
            <Typography variant="body1">
              Live office hours Thursdays 3pm ET{" "}
            </Typography>
            <Link
              style={{ textDecoration: "none" }}
              to={`https://zoom.us/j/96321853113?pwd=bkdvsJLawpWIsi85blaIenB2xwmorg.1`}
              target="_blank"
            >
              <Button sx={{ width: "100%" }} variant="contained">
                JOIN
              </Button>
            </Link>
            <Typography variant="body1">Book a 15min Help call </Typography>
            <Link
              style={{ textDecoration: "none" }}
              to={`https://scheduler.zoom.us/luke-kalp/sooth-fyi-15-min-help-call`}
              target="_blank"
            >
              <Button sx={{ width: "100%" }} variant="contained">
                BOOK
              </Button>
            </Link>{" "}
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body5">
            Checkout our{" "}
            <Link style={{ textDecoration: "none" }} to={`/profile/support`}>
              <Typography
                variant="body5"
                component="span"
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                onClick={() => navigate(`/profile/support`)}
                sx={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Help Videos
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default FloatingHelpButton;
