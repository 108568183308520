import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  Popover,
  useTheme,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { setfeedbackPopup } from "context/features/stylesSlice";
import { wordpressURL } from "config";
import { logEvent } from "services/amplitude.service";
import PopupFeedback from "../popup/PopupFeedback";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { useUserData } from "features/User";
import { staticsite } from "config";
import { useIsTab } from "hooks/is-tab.hook";

export default function Footer({ sx }) {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { accountType } = useUserData();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event, href, term) => {
    event.preventDefault();
    logEvent(`click_footer_${term}`, { [term]: "clicked", url: href });
    window.open(href, "_blank", "noopener noreferrer");
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

    const handleNavigation = (event) => {
      event.preventDefault(); 
      navigate("/profile/support");
      logEvent("click_footer_support", {
        support: "clicked",
        url: `${window.location.origin}/profile/support`,
      });
    };

  const open = Boolean(anchorEl);
  const isTab = useIsTab();
  const id = open ? "social-media-popover" : undefined;

  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        position: "static",
        py: isMobile ? 3 : 1,
        px: isMobile ? 1 : 6,
        ...(isMobile && { mb: 3 }),
        bgcolor: palette.background.default,
        height: isMobile ? "auto": "5vh",
        ...sx,
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        spacing={isTab ? 0: 2}
      >
        <Stack
          direction="row"
          sx={{
            cursor: "pointer",
            "& > p": {
              color: `${palette.text.secondary} !important`,
            },
          }}
          spacing={2}
          alignItems="center"
        >
          <Typography variant="body2" component="p">
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                dispatch(setfeedbackPopup(true));
                logEvent("click_footer_feedback", { feedback: "clicked" });
              }}
              underline="hover"
            >
              Feedback
            </Link>
          </Typography>
          <Typography variant="body2" component="p">
            <Link
              href={`https://${staticsite}sooth.fyi/privacy-policy/`}
              target="_blank"
              onClick={(event) => {
                logEvent("click_footer_privacy", {
                  feedback: "clicked",
                  url: `https://${staticsite}sooth.fyi/privacy-policy/`,
                });
              }}
              underline="hover"
            >
              Privacy
            </Link>
          </Typography>
          <Typography variant="body2" component="p">
            <Link
              href={`https://${staticsite}sooth.fyi/terms-of-use/`}
              target="_blank"
              onClick={(event) => {
                logEvent("click_footer_terms", {
                  feedback: "clicked",
                  url: `https://${staticsite}sooth.fyi/terms-of-use/`,
                });
              }}
              underline="hover"
            >
              Terms of Use
            </Link>
          </Typography>
          {accountType !== "trial" && (
            <Typography variant="body2" component="p">
              <RouterLink
                onClick={(event) => {
                  logEvent("click_footer_site_agreement", {
                    feedback: "clicked",
                    url: `${window.location.origin}/profile/subscription/subscriber-agreement`,
                  });
                }}
                to="/profile/subscription/subscriber-agreement"
              >
                Site Access Agreement
              </RouterLink>
            </Typography>
          )}
        </Stack>

        {!isMobile && <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2" component="p">
            <Link
              href={`/profile/support`}
              underline="hover"
              onClick={handleNavigation}
            >
              Help Videos
            </Link>
          </Typography>
          <Typography variant="body2" component="p">
            <Link
              href={`${wordpressURL}/faq/`}
              onClick={(e) => handleClick(e, `${wordpressURL}/faq/`, "faq")}
              underline="hover"
            >
              FAQ
            </Link>
          </Typography>

          <Typography
            onClick={() =>
              logEvent("click_footer_email", {
                email: "clicked",
                email: "contact@sooth.fyi",
              })
            }
            variant="body2"
            component="p"
          >
            <Link href="mailto:contact@sooth.fyi" underline="hover">
              Contact Us
            </Link>
          </Typography>

          <Typography variant="body2" component="p">
            <Link href="#" onClick={handlePopoverOpen} underline="hover">
              Follow
            </Link>
          </Typography>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Stack direction="column" spacing={1} sx={{ p: 2 }}>
              <IconButton
                onClick={(e) =>
                  handleClick(e, "https://twitter.com/Sooth_fyi", "twitter")
                }
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                onClick={(e) =>
                  handleClick(
                    e,
                    "https://www.facebook.com/sooth.fyi",
                    "facebook"
                  )
                }
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                onClick={(e) =>
                  handleClick(
                    e,
                    "https://www.linkedin.com/company/sooth-fyi/",
                    "linkedin"
                  )
                }
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                onClick={(e) =>
                  handleClick(
                    e,
                    "https://instagram.com/sooth.fyi/",
                    "instagram"
                  )
                }
              >
                <InstagramIcon />
              </IconButton>
            </Stack>
          </Popover>
        </Stack>}
      </Stack>
      <PopupFeedback />
    </Box>
  );
}
