import { Folder } from "@mui/icons-material";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export default function Card({ collection }) {
  const { palette } = useTheme();
  return (
    <Link
      to={`/collections/${collection.collectionID_ids}`}
      style={{ textDecoration: "none", height: "100%" }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          height: "100%",
          boxShadow: 0,
          backgroundColor: palette.secondary.main,
          justifyContent: "space-between",
          gap: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Folder sx={{ color: "#a9bdca" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  lineHeight: "1.5em",
                  maxHeight: "3em",
                  wordBreak: "break",
                }}
                variant="body5"
              >
                {collection.collectionName_chr}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "1.1rem", color: palette.text.secondary }}
          >
            {collection.lastUpdatedDisplay_chr}
          </Typography>
        </Box>
        <Box sx={{ ml: 5, mt: 0 }}>
          <Typography
            sx={{ fontSize: "1.1rem", color: palette.text.secondary }}
          >
            {collection.collectionSource_Count_int} bookmarks
          </Typography>
        </Box>
      </Paper>
    </Link>
  );
}
