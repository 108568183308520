import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RecentTableContent from "features/Collections/components/RecentTable";
import useRecentCollection from "features/Collections/hooks/use-recent-collection.hook";
import CollectionCard from "features/Collections/components/CollectionCard";
import { fillParent, scrollingBox } from "utils/base-styles";
import Footer from "components/layout/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Parallax, Pagination, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import "./styles.css";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import PageTitle from "components/layout/PageTitle";

SwiperCore.use([Parallax, Pagination, Mousewheel]);

export default function RecentsCollections() {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const {
    filteredRecentCollection,
    recentCollectionSource,
    recentCollections,
  } = useRecentCollection(filter);

  const [filteredBookmarks, setFilteredBookmarks] = useState([]);

  const isMobile = useIsMobile();

  const handleFilterBookmark = () => {
    const filteredBookmark = recentCollectionSource.filter(
      (bookmark) =>
        bookmark.title_chr.toLowerCase().includes(filter.toLowerCase()) ||
        bookmark.sourceName_chr.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredBookmarks(filteredBookmark);
  };

  useEffect(() => {
    handleFilterBookmark();
  }, [filter, recentCollectionSource]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
      }}
    >
      <Stack direction="column" gap={4}>
        <Stack
          mt={3}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <PageTitle alignItems="center" variant="h3" sx={{ mt: 0 }}>
            {filter ? "Search Bookmarks" : "Recent Activity"}
          </PageTitle>
          <TextField
            onChange={(e) => setFilter(e.target.value)}
            sx={{ width: {xs: "50%", sm: "30%"} }}
            value={filter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ height: 30 }} />
                </InputAdornment>
              ),
              endAdornment: filter && (
                <InputAdornment position="end">
                  <ClearIcon
                    sx={{ cursor: "pointer", height: 30 }}
                    onClick={() => setFilter("")}
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Search Collections"
          />
        </Stack>
        {!filter && (
          <Box sx={{mb: {xs: 2, sm: 0}}}>
            {!isMobile ? (
              <Grid container spacing={2}>
                {recentCollections.map((collection, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <CollectionCard collection={collection} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ position: "relative" }}>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={2}
                  pagination={{ clickable: true, el: ".swiper-pagination" }}
                  mousewheel
                  parallax
                  speed={600}
                  style={{ marginTop: "20px" }}
                >
                  {recentCollections.map((collection, index) => (
                    <SwiperSlide key={index}>
                      <CollectionCard collection={collection} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiper-pagination" />
              </Box>
            )}
          </Box>
        )}
        {!filter && <Typography variant="h4">Recent Bookmarks</Typography>}
        <RecentTableContent
          recentCollectionSource={
            filter ? filteredRecentCollection : recentCollectionSource
          }
        />
      </Stack>
      <Footer sx={{px: 0}} />
    </Box>
  );
}
