import dayjs from "lib/dayjs";

export class VimeoVideoMetadata {
  #durationSeconds;
  #id;
  constructor({
    id,
    title,
    thumbnailLarge,
    description,
    uploadDate,
    duration,
    tags,
  }) {
    this.#id = id.toString();
    this.title = title;
    this.thumbnail = thumbnailLarge;
    this.description = description;
    this.uploadDate = dayjs(uploadDate);
    this.#durationSeconds = duration;
    this.tags = tags.split(", ");
  }

  get id() {
    return this.#id;
  }

  get date() {
    return this.uploadDate;
  }

  get dateOrder() {
    return this.uploadDate.format("YYYYMMDD");
  }

  get duration() {
    const hours = Math.floor(this.#durationSeconds / 3600);
    const minutes = Math.floor((this.#durationSeconds - hours * 3600) / 60);
    const seconds = this.#durationSeconds - hours * 3600 - minutes * 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
}
